import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import { foodservicePath } from 'routes'

const Sortiment = ({ data }) => {
  return (
    <Layout
      pageTitle='Sortiment'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row row--with-auto-margin'>
        <section className='section section--centered section--with-background'>
          <h1 className='page__heading'>Sortiment</h1>
          <p className='section__text section__text--margin-top'>
            Vi är verksamma inom två olika områden. För privatpersoner har vi
            vårt varumärke Hägges. För Food Service-branschen har vi ett antal
            olika varumärken att välja mellan.
          </p>
          <div className='logotypes'>
            <a href='https://hagges.se' target='_blank' rel='noreferrer'>
              <GatsbyImage
                image={data.imageHagges.childImageSharp.gatsbyImageData}
                alt='Logotyp Hägges'
                className='logotype'
              />
            </a>
            <Link to={foodservicePath}>
              <GatsbyImage
                image={data.foodservice.childImageSharp.gatsbyImageData}
                alt='Foodservice trademarks'
                className='logotype'
              />
            </Link>
          </div>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "chocolate-fika.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    imageHagges: file(relativePath: { eq: "sortiment-hagges.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 380, quality: 80, layout: CONSTRAINED)
      }
    }
    foodservice: file(relativePath: { eq: "sortiment-foodservice.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 380, quality: 80, layout: CONSTRAINED)
      }
    }
  }
`

export default Sortiment
